/**!
 * @license
 * ares4 v1.0 Bilbao
 *
 * http://rrooaarr.com
 * Copyright 2015 rrooaarr interactive solutions GmbH
 **/
var Breadcrumb = (function (Global, NavigationStack) {
    var module = {};
    module.container = null;
    module.render = function () {
        module.container.empty();
        //if (Global.DEBUG) console.log('render BreadCrumb');
        module.container.append('<a class="filter home" href="/' + NavigationStack.base + '"><i class="fa fa-home"></i> Home</a>');
        var tmpRoute = NavigationStack.base;
        NavigationStack.stack.forEach(function (item) {
            tmpRoute += '/' + item.name;
            var displayName = item.displayName;
            if (item.navigationName !== undefined && item.navigationName !== null && item.navigationName !== '') {
                displayName = item.navigationName;
            }
            module.container.append('<a class="filter" href="/' + tmpRoute + '" data-id="' + item.id + '"><i class="fa fa-caret-right"></i> ' + displayName + '</a>');
        });
        return module;
    };
    return module;
})(Global, NavigationStack);
