console.log("|-----LOAD: MainModule.js -----");
var Main = (function(Global, Router, NavigationStack, PageHandler, InfoRoom) {
  var module = {};
  var router = Router;
  var infoRoom = InfoRoom;
  var isInit = true;
  //if (Global.DEBUG) console.log(Router);
  //if (Global.DEBUG) console.log('Create Routes');
  router.routes({
    printUnit: function(roomName, unitNames) {
      if (Global.DEBUG) console.log("unit " + roomName + " " + unitNames);
      infoRoom.loadRoom(Global.roomUrl, false, function() {
        var units = [];
        for (var i = 0; i < unitNames.length; ++i) {
          if (
            !NavigationStack.stack[i] ||
            NavigationStack.stack[i].name != unitNames[i]
          ) {
            units.push(infoRoom.getUnitByName(unitNames[i]));
          } else {
            units.push(NavigationStack.stack[i]);
          }
        }
        NavigationStack.init(
          roomName !== Global.host ? "/" + roomName : "",
          null,
          units
        );

        //console.log(NavigationStack);
        var targetUnit = NavigationStack.getTarget();
        PageHandler.setHeader(infoRoom, targetUnit);
        PageHandler.buildBody(infoRoom);
        Breadcrumb.render();
        PageHandler.triggerNavigation(infoRoom);
        PageHandler.triggerUnit(infoRoom, targetUnit);
        if (isInit) {
          /*$(document).ready(function () {
                        $(window).on('resizeend', 1500, function () {
                            $.force_appear();
                        });
                    });*/
          isInit = false;
        }
      });
      window.print();
    },
    printRoom: function(roomName) {
      //if (Global.DEBUG) console.log('printRoom ' + roomName);
    },
    search: function(roomName, search, unitNames) {
      if (Global.DEBUG) console.log("searchRoom 3" + search);
      if (Global.DEBUG) console.log("searchRoom 4" + unitNames);
      infoRoom.loadRoom(Global.roomUrl, false, function() {
        var units = null;
        if (!!unitNames) {
          if (Global.DEBUG) console.log("in !!unitNames" + search);
          units = [];
          for (var i = 0; i < unitNames.length; ++i) {
            if (
              !NavigationStack.stack[i] ||
              NavigationStack.stack[i].name != unitNames[i]
            ) {
              units.push(infoRoom.getUnitByName(unitNames[i]));
            } else {
              units.push(NavigationStack.stack[i]);
            }
          }
        }

        NavigationStack.init(
          roomName !== Global.host ? "/" + roomName : "",
          search,
          units
        );

        var targetUnit = NavigationStack.getTarget();
        PageHandler.setHeader(infoRoom, targetUnit);
        PageHandler.buildBody(infoRoom);
        Breadcrumb.render();
        PageHandler.search(infoRoom, search);
        if (!!targetUnit) {
          PageHandler.triggerUnit(infoRoom, NavigationStack.target);
        }
        if (isInit) {
          /*$(document).ready(function () {
                        $(window).on('resizeend', 1500, function () {
                            $.force_appear();
                        });
                    });*/
          isInit = false;
        }
      });
    },
    unit: function(roomName, unitNames) {
      if (Global.DEBUG) console.log("unit " + roomName + " " + unitNames);
      infoRoom.loadRoom(Global.roomUrl, false, function() {
        var units = [];
        for (var i = 0; i < unitNames.length; ++i) {
          if (
            !NavigationStack.stack[i] ||
            NavigationStack.stack[i].name != unitNames[i]
          ) {
            units.push(infoRoom.getUnitByName(unitNames[i]));
          } else {
            units.push(NavigationStack.stack[i]);
          }
        }
        NavigationStack.init(
          roomName !== Global.host ? "/" + roomName : "",
          null,
          units
        );

        //console.log(NavigationStack);
        var targetUnit = NavigationStack.getTarget();
        PageHandler.setHeader(infoRoom, targetUnit);
        PageHandler.buildBody(infoRoom);
        Breadcrumb.render();
        //if (!targetUnit.navDelay) {
        PageHandler.triggerNavigation(infoRoom);
        //}
        PageHandler.triggerUnit(infoRoom, targetUnit);
        if (isInit) {
          /*$(document).ready(function () {
                        $(window).on('resizeend', 1500, function () {
                            $.force_appear();
                        });
                    });*/
          isInit = false;
        }
      });
    },
    room: function(roomName) {
      if (Global.DEBUG) console.log("MainModule.js: router.routes.room(" + roomName + ")");
      infoRoom.loadRoom(Global.roomUrl, false, function() {
        //console.log('loadRoomCallback');
        /*if (Global.DEBUG) console.log('Print Modules in Room Home');
                if (Global.DEBUG) console.log(Global);
                if (Global.DEBUG) console.log(Router);
                if (Global.DEBUG) console.log(InfoRoom);
                if (Global.DEBUG) console.log(module);
                if (Global.DEBUG) console.log('/ Print Modules in Room Home');*/

        NavigationStack.init(roomName !== Global.host ? "/" + roomName : "");
        PageHandler.setHeader(infoRoom);
        PageHandler.buildBody(infoRoom);
        Breadcrumb.render();
        if (isInit) {
          /*$(document).ready(function () {
                        $(window).on('resizeend', 1500, function () {
                            //console.log('reszieend');
                            $.force_appear();
                        });
                    });*/
          isInit = false;
          PageHandler.triggerNavigation(infoRoom);
        } else {
          PageHandler.filter(infoRoom);
        }

        console.log("loadRoom callback");
        //console.log('loadRoomEnd');
      });

      //console.log('afterLoadRoom');
      //if (Global.DEBUG) console.log('room ' + roomName);
    },
    home: function() {
      //if (Global.DEBUG) console.log('home');
    }
  });
  module.myscroll = "nein";
  //if (Global.DEBUG) console.log(router);
  module.start = function() {
    //console.log(module.myscroll);
    /*if (module.myscroll == "nein") {
            $(document).ready(function () {
                warten = setInterval(function () {
                    window.clearInterval(warten);
                    module.myscroll = "ja";

                    fensterhoehe = $(window).height();
                    wrapperhoehe = $('body').height();

                    if (wrapperhoehe <= fensterhoehe) {
                        $('body').height((fensterhoehe + 10));

                        warten2 = setInterval(function () {
                            window.clearInterval(warten2);
                            window.scrollTo(0, 5);
                            window.scrollTo(0, 0);

                            $('body').height(wrapperhoehe);

                        }, 1000);
                    }
                    else {
                        window.scrollTo(0, 1);
                        window.scrollTo(0, 0);
                    }

                    //window.scrollTo(0, 1);
                    //window.scrollTo(0, 0);
                }, 1000);
            });
        }*/
    router.start();
    return module;
  };
  return module;
})(Global, Router, NavigationStack, PageHandler, InfoRoom);

Main.start();
