/**!
 * @license
 * ares4 v1.0 Bilbao
 *
 * http://rrooaarr.com
 * Copyright 2015 rrooaarr interactive solutions GmbH
 **/
console.log("|-----LOAD: PageHandler.js -----");
var PageHandler = (function(Global, Router, NavigationStack, Breadcrumb, window, document) {
  var module = {};
  module.quicksearch = null;
  module.breadcrumb = null;
  module.isotopeGrid = null;
  module.isBodyBuilt = false;
  module.isPrintBuilt = false;
  module.triggeredUnit = null;
  module.externalTitle = null;
  module.headerForDefault = function(inforoom) {};
  module.setHeader = function(infoRoom, targetUnit) {
    if (!InfoRoom.data.isExternal) {
      if (!!infoRoom) {
        $("meta[name=title]").remove();
        $("meta[name=type]").remove();
        $("meta[name=url]").remove();
        $("meta[name=image]").remove();
        $("meta[name=keywords]").remove();
        $("meta[name=description]").remove();
      }

      if (!!infoRoom && !!infoRoom.data && !!targetUnit) {
        document.title =
          (!!infoRoom.data.displayName ? infoRoom.data.displayName + " - " : "") +
          (!!targetUnit.title
            ? targetUnit.title
            : !!targetUnit.teaserTitle
            ? targetUnit.teaserTitle
            : targetUnit.displayName);
        $("head").append(
          '<meta name="title" property="og:title" content="' +
            (!!infoRoom.data.displayName ? infoRoom.data.displayName + " - " : "") +
            (!!targetUnit.title
              ? targetUnit.title
              : !!targetUnit.teaserTitle
              ? targetUnit.teaserTitle
              : targetUnit.displayName) +
            '" />'
        );
        $("head").append('<meta name="type" property="og:type" content="article" />');
        $("head").append(
          '<meta name="url" property="og:url" content="' + (Global.baseUrl + NavigationStack.currentTargetURL) + '" />'
        );
        console.log("targetUnit:", targetUnit);

        var facebookImage = targetUnit.facebookImage || targetUnit.teaserBackgroundImage;
        if (!!facebookImage) {
          $("head").append(
            '<meta name="image" property="og:image" content="' +
              (facebookImage.indexOf("base64") === -1
                ? Global.unitUrl + targetUnit.id + "/file/" + facebookImage
                : facebookImage) +
              '" />'
          );
        } else if (!!infoRoom.data.logoImage) {
          $("head").append(
            '<meta name="image" property="og:image" content="' +
              (infoRoom.data.logoImage.indexOf("base64") === -1
                ? Global.roomFileUrl + infoRoom.data.logoImage
                : infoRoom.data.logoImage) +
              '" />'
          );
        }
        if (!!targetUnit.keywords)
          $("head").append('<meta name="keywords" property="og:keywords" content="' + targetUnit.keywords + '" />');
        if (!!targetUnit.description)
          $("head").append(
            '<meta name="description" property="og:description" content="' + targetUnit.description + '" />'
          );
      } else if (!!infoRoom && !!infoRoom.data) {
        if (!infoRoom.data.isExternal) {
          document.title = !!infoRoom.data.displayName ? infoRoom.data.displayName : "Ares4";
          $("head").append(
            '<meta name="title" property="og:title" content="' +
              (!!infoRoom.data.displayName ? infoRoom.data.displayName : "Ares4") +
              '" />'
          );
        }
        $("head").append('<meta name="type" property="og:type" content="article" />');
        $("head").append(
          '<meta name="url" property="og:url" content="' + (Global.baseUrl + NavigationStack.currentTargetURL) + '" />'
        );
        if (!!infoRoom.data.logoImage) {
          $("head").append(
            '<meta name="image" property="og:image" content="' +
              (infoRoom.data.logoImage.indexOf("base64") === -1
                ? Global.roomFileUrl + infoRoom.data.logoImage
                : infoRoom.data.logoImage) +
              '" />'
          );
        } else if (!!infoRoom.data.backgroundImage) {
          $("head").append(
            '<meta name="image" property="og:image" content="' +
              (infoRoom.data.backgroundImage.indexOf("base64") === -1
                ? Global.roomFileUrl + infoRoom.data.backgroundImage
                : infoRoom.data.backgroundImage) +
              '" />'
          );
        }
        if (!!infoRoom.data.keywords)
          $("head").append('<meta name="keywords" property="og:keywords" content="' + infoRoom.data.keywords + '" />');
        if (!!infoRoom.data.description)
          $("head").append(
            '<meta name="description" property="og:description" content="' + infoRoom.data.description + '" />'
          );
      } else {
        document.title = "Ares4";
      }
    } else {
      if (!module.externalTitle) {
        module.externalTitle = $(document)
          .find("title")
          .text();
      }
      if (!!infoRoom && !!infoRoom.data && !!targetUnit) {
        document.title =
          module.externalTitle +
          " - " +
          (!!targetUnit.title
            ? targetUnit.title
            : !!targetUnit.teaserTitle
            ? targetUnit.teaserTitle
            : targetUnit.displayName);
      }
      /*if (!module.externalTitle) {
        $(document).ready(module.setTitleAsync);
      }
      else if (!!infoRoom && !!infoRoom.data && !!targetUnit) {
        document.title = module.externalTitle + ' - ' +
          (!!targetUnit.title ? targetUnit.title : (!!targetUnit.teaserTitle ? targetUnit.teaserTitle : targetUnit.displayName));
      }*/
    }
    return module;
  };
  module.setTitleAsync = function() {
    console.log(document);
    module.externalTitle = $(document)
      .find("title")
      .text();
    if (!!infoRoom && !!infoRoom.data && !!targetUnit) {
      document.title =
        module.externalTitle +
        " - " +
        (!!targetUnit.title
          ? targetUnit.title
          : !!targetUnit.teaserTitle
          ? targetUnit.teaserTitle
          : targetUnit.displayName);
    }
  };
  module.buildBody = function(infoRoom) {
    if (!!infoRoom && !!infoRoom.data && !module.isBodyBuilt) {
      module.container = $("#ares4");

      var header = module.container.find("header");
      //console.log(header);
      if (!header.html() || header.length == 0) {
        header.append(
          "<header>" +
            '<div id="logo" class="logo"></div>' +
            '<div id="searchbar">' +
            '<input type= "text" id= "search" placeholder= "Suche..." />' +
            "</div>" +
            '<div id="breadcrumb"></div>' +
            "</header>"
        );
      }
      var logo = module.container.find("#logo");
      //console.log(NavigationStack.base);
      if (!!logo) {
        module.container
          .find("#logo")
          .append(
            !!infoRoom.data.logoImage
              ? '<a title="' +
                  (!!infoRoom.data.displayName ? infoRoom.data.displayName : "") +
                  '" href="' +
                  (!!NavigationStack.base ? NavigationStack.base : "/" + NavigationStack.base) +
                  '"><img alt="' +
                  (!!infoRoom.data.displayName ? infoRoom.data.displayName : "") +
                  '" src="' +
                  (infoRoom.data.logoImage.indexOf("base64") === -1
                    ? Global.roomFileUrl + infoRoom.data.logoImage
                    : infoRoom.data.logoImage) +
                  '" /></a>'
              : ""
          );
      }
      var main = module.container.find("main");
      //console.log(main);
      if (!main.html() || main.length == 0) {
        module.container
          .find("main")
          .append(
            '<div id="grid" class="ie-grid">' +
              '<div class="grid-sizer"></div>' +
              '<div class="grid-gutter"></div>' +
              "</div>"
          );
      }
      var grid = module.container.find("#grid");
      if (!!grid && grid.length > 0) {
        if (!grid.html()) {
          grid.prepend('<div class="grid-sizer"></div>' + '<div class="grid-gutter"></div>');
        }
      } else {
        main.append(
          '<div id="grid" class="ie-grid">' +
            '<div class="grid-sizer"></div>' +
            '<div class="grid-gutter"></div>' +
            "</div>"
        );
      }
      var footer = module.container.find("footer");
      if (!footer.html()) {
        //$('footer').append();
      }
      if (!!infoRoom.data.backgroundImage) {
        module.container.css(
          "background",
          "URL(" +
            (infoRoom.data.backgroundImage.indexOf("base64") === -1
              ? Global.roomFileUrl + infoRoom.data.backgroundImage
              : infoRoom.data.backgroundImage) +
            ") no-repeat"
        );
        module.container.css("background-size", "cover");
        module.container.css("background-position", "center center");
      }
      if (!!infoRoom.data.backgroundColor) {
        module.container.css("background-color", infoRoom.data.backgroundColor);
      }
      module.buildGrid(infoRoom);
      module.initPageEventListener(infoRoom);
      module.isBodyBuilt = true;
    }
  };
  module.buildGrid = function(infoRoom) {
    // Checks if the youtube url is is undefined, null, or only has whitespace(s). If so then return false, otherwise true.
    function hasYoutubeVideoID(youtubeVideoID) {
      var isStringEmptyUndefinedNull = !!youtubeVideoID === false || !youtubeVideoID.trim();
      return !isStringEmptyUndefinedNull;
    }

    function createYoutubeElement(youtubeVideoID) {
      if (!hasYoutubeVideoID(youtubeVideoID)) {
        return "";
      }
      var youtubeElement = '\n        <iframe class="video" src="https://www.youtube.com/embed/'.concat(
        youtubeVideoID,
        '" frameborder="0" allow="autoplay; encrypted-media; picture-in-picture" allowfullscreen></iframe>\n      '
      );
      return youtubeElement;
    }

    if (!module.isotopeGrid) {
      module.isotopeGrid = module.container.find("#grid");
      var gridHtml = "";
      if (!!infoRoom.data.infounits) {
        Object.keys(infoRoom.data.infounits).forEach(function(key, index) {
          var contentContainer = this[key];

          var name = contentContainer.name;
          var id = contentContainer.id;
          var priority = contentContainer.priority;
          var creationDate = contentContainer.creationDate;
          var navMetas = contentContainer.navMetas;
          var teaserSizeX = contentContainer.teaserSizeX;
          var teaserSizeY = contentContainer.teaserSizeY;
          var teaserTitle = contentContainer.teaserTitle;
          var teaserBackgroundColor = contentContainer.teaserBackgroundColor;
          var cssFrontId = contentContainer.cssFrontId;
          var teaserTitleColor = contentContainer.teaserTitleColor;
          var teaserSubtitleColor = contentContainer.teaserSubtitleColor;
          var teaserSubtitle = contentContainer.teaserSubtitle;
          var youtubeVideoId = contentContainer.youtubeVideoId;

          function createTitleElement() {
            var isTitleEmptyUndefinedNull = !!teaserTitle === false || !teaserTitle.trim();

            if (isTitleEmptyUndefinedNull) {
              return "";
            } else {
              return "\n                <h3 style='"
                .concat(hasYoutubeVideoID(youtubeVideoId) ? "position:relative;" : "", " color:")
                .concat(!!teaserTitleColor ? teaserTitleColor : "#333", " ;'>\n                  ")
                .concat(!!teaserTitle ? teaserTitle : "", "\n                </h3>\n              ");
            }
          }

          function createSubtitleElement() {
            var isSubtitleEmptyUndefinedNull = !!teaserSubtitle === false || !teaserSubtitle.trim();

            if (isSubtitleEmptyUndefinedNull) {
              return "";
            } else {
              return "\n                <h4 style='"
                .concat(hasYoutubeVideoID(youtubeVideoId) ? "position:relative;" : "", " color:")
                .concat(!!teaserSubtitleColor ? teaserSubtitleColor : "#333", " ;'>\n                  ")
                .concat(!!teaserSubtitle ? teaserSubtitle : "", "\n                </h4>\n              ");
            }
          }

          // prettier-ignore
          gridHtml += "\n            <div\n              id =".concat(name, "\n              data-id=").concat(id, " \n              data-priority=").concat(priority == null || priority == undefined ? 30 : priority, " \n              data-date=").concat(creationDate, "\n              class=\"").concat(!!navMetas ? 'filter ' : '', " ie ie-width-").concat(teaserSizeX, " ie-height-").concat(teaserSizeY, "\"\n              >\n              \n              ").concat(hasYoutubeVideoID(youtubeVideoId) ? "<div " : "<a ", "\n                alt='").concat(!!teaserTitle ? teaserTitle : "", "'\n                title='").concat(!!teaserTitle ? teaserTitle : "", "' \n                style='display: block; background-color: ").concat(!!teaserBackgroundColor ? teaserBackgroundColor : "#fff", ";'\n                class='front front").concat(!!cssFrontId ? "-".concat(cssFrontId) : "-1", "'>\n\n                ").concat(createYoutubeElement(youtubeVideoId), "\n                ").concat(createTitleElement(), "\n                ").concat(createSubtitleElement(), "\n              \n                ").concat(hasYoutubeVideoID(youtubeVideoId) ? "</div>" : "</a>", "\n            </div>\n          ");
        }, infoRoom.data.infounits);
      }

      module.isotopeGrid.append(gridHtml);
      gridHtml = null;

      module.isotopeGrid = module.container.find("#grid").isotope({
        itemSelector: ".ie",
        layoutMode: "packery",
        masonry: {
          //columnWidth: 102,
          columnWidth: ".grid-sizer",
          gutter: ".grid-gutter"
        },
        packery: {
          //columnWidth: 102,
          columnWidth: ".grid-sizer",
          gutter: ".grid-gutter"
        },
        transitionDuration: "0.6s",
        getSortData: {
          id: "[data-id] parseInt",
          priority: "[data-priority] parseInt",
          date: "[data-date]"
        },
        //sortBy: ['original-order'],
        sortBy:
          infoRoom.data.sorting == "ID"
            ? ["id"]
            : infoRoom.data.sorting == "PRIORITY"
            ? ["priority"]
            : infoRoom.data.sorting == "DATE"
            ? ["date"]
            : ["id"],
        sortAscending: infoRoom.data.sorting == "DATE" ? false : true,
        filter: !!NavigationStack.getLast()
          ? function() {
              return InfoRoom.metaFilter(NavigationStack.getLast(), $(this).attr("data-id"));
            }
          : !!NavigationStack.getTarget() &&
            !InfoRoom.metaFilter(!!infoRoom.data ? infoRoom.data.startMetas : null, NavigationStack.getTarget().id)
          ? function() {
              return $(this).attr("id") == NavigationStack.getTarget().name;
            }
          : function() {
              return InfoRoom.metaFilter(!!infoRoom.data ? infoRoom.data.startMetas : null, $(this).attr("data-id"));
            }
      });

      module.container.find("#grid .ie").appear();
      module.initGridEventListener(infoRoom);
    }
  };
  module.search = function(infoRoom, search) {
    if (module.quicksearch.val() != search) {
      module.quicksearch.val(search);
    }
    module.container.find(".ie").removeClass("ie-maximized");
    module.container
      .find(".ie")
      .find(".back")
      .hide();
    module.container
      .find(".ie")
      .find(".back")
      .remove();
    module.container
      .find(".ie")
      .find(".front")
      .show();
    $qsRegex = new RegExp(search, "gi");
    module.isotopeGrid.isotope({
      filter: function() {
        var infounit = infoRoom.data.infounits[$(this).attr("data-id")];
        var tmp =
          (!!infounit.teaserTitle ? infounit.teaserTitle + " " : "") +
          (!!infounit.teaserSubtitle ? infounit.teaserSubtitle + " " : "");
        tmp += (!!infounit.title ? infounit.title + " " : "") + (!!infounit.subTitle ? infounit.subTitle + " " : "");
        //console.log(infounit);
        if (!!infounit.keywords) {
          tmp += infounit.keywords + " ";
        }
        if (!!infounit.searchTerms) {
          tmp += infounit.searchTerms + " ";
        }
        if (!!infounit.tabs) {
          for (var i = 0; i < infounit.tabs.length; ++i) {
            tmp +=
              (!!infounit.tabs[i].title ? infounit.tabs[i].title : "") +
              (!!infounit.tabs[i].subTitle ? infounit.tabs[i].subTitle : "") +
              (!!infounit.tabs[i].text ? infounit.tabs[i].text + " " : "");
          }
        }
        if (!!infounit.metas) {
          for (var i = 0; i < infounit.metas.length; ++i) {
            tmp += !!infounit.metas[i].name ? infounit.metas[i].name : "";
          }
        }
        return $qsRegex ? +!!tmp.match($qsRegex) : true;
      }
    });
  };
  module.filter = function(infoRoom) {
    module.container.find(".ie").removeClass("ie-maximized");
    module.container
      .find(".ie")
      .find(".back")
      .hide();
    module.container
      .find(".ie")
      .find(".back")
      .remove();
    module.container
      .find(".ie")
      .find(".front")
      .show();
    /*module.isotopeGrid.isotope({
      filter: !!NavigationStack.getLast() ? function () {
        return InfoRoom.metaFilter(NavigationStack.getLast(), $(this).attr('data-id'));
      } : function () {
        return InfoRoom.metaFilter(!!infoRoom.data ? infoRoom.data.startMetas : null, $(this).attr('data-id'));
      }
    });*/
    module.triggerNavigation(infoRoom);
  };
  module.triggerNavigation = function(infoRoom) {
    var lastNav = NavigationStack.getLast();
    if (!!lastNav) {
      module.isotopeGrid.isotope({
        filter: function() {
          if (!!lastNav) {
            var tmp = infoRoom.metaFilter(lastNav.navMetas, $(this).attr("data-id"));
            module.updateUnitHref($(this), tmp);
            return tmp;
          }
          return true;
        }
      });
    } else {
      var target = NavigationStack.getTarget();
      if (!!target && !InfoRoom.metaFilter(!!infoRoom.data ? infoRoom.data.startMetas : null, target.id)) {
        //Filter für tote Kachel
        module.isotopeGrid.isotope({
          filter: function() {
            var tmp = $(this).attr("id") == target.name;
            module.updateUnitHref($(this), tmp);
            return tmp;
          }
        });
      } else {
        module.isotopeGrid.isotope({
          filter: function() {
            var tmp = InfoRoom.metaFilter(!!infoRoom.data ? infoRoom.data.startMetas : null, $(this).attr("data-id"));
            module.updateUnitHref($(this), tmp);
            return tmp;
          }
        });
      }
    }
    //console.log(ga.get('page'));

    //if (!!Global.mainTrackingId) {
    //ga('set', 'page', NavigationStack.updateTargetURL()/*.replace(NavigationStack.base, '')*/);
    //console.log(NavigationStack.updateTargetURL().replace(NavigationStack.base, ''));
    //console.log(ga.get('page'));
    //ga('send', 'pageview');
    //}

    //console.log(infoRoom);
    if (!!infoRoom && !!infoRoom.data && !!infoRoom.data.trackingId) {
      //console.log('trigger send');
      ga("clientTracker.set", "page", NavigationStack.updateTargetURL() /*.replace(NavigationStack.base, '')*/);
      ga("clientTracker.send", "pageview");
    }
  };
  module.updateUnitHref = function(unitSelector, setLink) {
    if (!!InfoRoom.data && !!InfoRoom.data.infounits && !!InfoRoom.data.infounits[unitSelector.attr("data-id")]) {
      var unit = InfoRoom.data.infounits[unitSelector.attr("data-id")];
      if (unit.type == "NAV_EXTERNAL_LINK") {
        unitSelector.find(".front").attr("href", setLink ? unit.navExternalLink : "");
        unitSelector.find(".front").attr("target", setLink ? "_blank" : "");
      } else {
        unitSelector
          .find(".front")
          .attr("href", setLink ? NavigationStack.currentURL + "/" + unitSelector.attr("id") : "");
      }
    } else {
      unitSelector
        .find(".front")
        .attr("href", setLink ? NavigationStack.currentURL + "/" + unitSelector.attr("id") : "");
    }
  };
  module.triggerUnit = function(infoRoom, unit) {
    if (!unit || !unit.name) {
      return;
    }
    var $box = module.container.find("#" + unit.name);
    module.triggeredUnit = $box;
    $showContent = true;
    if (!!unit && unit.type != "NAV_NO_CONTENT") {
      $showContent = true;
    } else {
      $showContent = false;
    }

    module.container
      .find(".ie")
      .not($box)
      .removeClass("ie-maximized");
    module.container
      .find(".ie")
      .not($box)
      .find(".back")
      .hide();
    module.container
      .find(".ie")
      .not($box)
      .find(".back")
      .remove();
    module.container
      .find(".ie")
      .not($box)
      .find(".front")
      .show();

    if ($showContent) {
      $box.find(".front").hide();
      if ($box.children().length == 1) {
        $box.append(
          '<div class="back back' +
            (!!unit.cssBackId ? "-" + unit.cssBackId : "-1") +
            ' ie-maximized" ' +
            (!!unit.backgroundColor || !!unit.backgroundImage
              ? 'style="' +
                (!!unit.backgroundColor ? "background-color: " + unit.backgroundColor + ";" : "") +
                (!!unit.backgroundImage
                  ? "background: url(" +
                    (unit.backgroundImage.indexOf("base64") === -1
                      ? Global.unitUrl + unit.id + "/file/" + unit.backgroundImage
                      : unit.backgroundImage) +
                    "); background-size: cover; background-position: center center;"
                  : "") +
                '"'
              : "") +
            ">" +
            '<i title="schließen" class="fa fa-times ie-close" href="#"></i>' +
            (!!unit.title || !!unit.subTitle
              ? (!!unit.title
                  ? '<h2 style="color:' + (!!unit.titleColor ? unit.titleColor : "#333") + ';">' + unit.title + "</h2>"
                  : "") /*+
          (!!tileObject.subtitle ? '<h4 style="color:'+ (!!tile.subtitleColor ? tile.subtitleColor : '#333') + ';">' + tileObject.subtitle + '</h4>' : '')*/ +
                "<hr>"
              : "") +
            '<div class="ie-tabnav">' +
            (!!unit.tabs
              ? (!!unit.tabs[0] && !!unit.tabs[0].title
                  ? '<a tab-id="0" href="#" class="active"><h3>' + unit.tabs[0].title + "</h3></a>"
                  : "") +
                (!!unit.tabs[1] && !!unit.tabs[1].title
                  ? '<a tab-id="1" href="#" class="inactive"><h3>' + unit.tabs[1].title + "</h3></a>"
                  : "") +
                (!!unit.tabs[2] && !!unit.tabs[2].title
                  ? '<a tab-id="2" href="#" class="inactive"><h3>' + unit.tabs[2].title + "</h3></a>"
                  : "")
              : "") +
            "</div>" +
            '<div class="ie-tabs">' +
            (!!unit.tabs
              ? (!!unit.tabs[0] && !!unit.tabs[0].text
                  ? '<div id="tab-0" style="display: block" class="ie-tab"><div>' + unit.tabs[0].text + "</div></div>"
                  : "") +
                (!!unit.tabs[1] && !!unit.tabs[1].text
                  ? '<div id="tab-1" class="ie-tab"><div>' + unit.tabs[1].text + "</div></div>"
                  : "") +
                (!!unit.tabs[2] && !!unit.tabs[2].text
                  ? '<div id="tab-2" class="ie-tab"><div>' + unit.tabs[2].text + "</div></div>"
                  : "")
              : "") +
            "</div>" +
            (!!unit.navDelay ? '<i title="schließen" class="fa fa-ellipsis-h ie-nav-delay" href="#"></i>' : "") +
            ((!infoRoom.data || !infoRoom.data.isExternal) &&
            (!!unit.prevId ||
              !!unit.nextId ||
              !!unit.facebook ||
              !!unit.xing ||
              !!unit.navDelay ||
              !!unit.linkedin ||
              !!unit.email ||
              !!unit.print)
              ? '<div class="ie-options">' +
                (!!unit.prevId
                  ? "<div>" +
                    '<a href="#' +
                    unit.prevId +
                    '">' +
                    '<i title="zu ' +
                    unit.prevId +
                    '" class="fa fa-chevron-left"></i>' +
                    "</a>" +
                    "</div>"
                  : "") +
                (!!unit.facebook
                  ? "<div>" +
                    '<a href="https://www.facebook.com/sharer/sharer.php?u=' +
                    encodeURI(Global.baseUrl + NavigationStack.currentURL + "/" + unit.name) +
                    '" target="_blank">' +
                    '<i title="teilen" class="fa fa-facebook-official"></i>' +
                    "</a>" +
                    "</div>"
                  : "") +
                (!!unit.xing
                  ? "<div>" +
                    '<a href="https://www.xing.com/spi/shares/new?url=' +
                    encodeURI(Global.baseUrl + NavigationStack.currentURL + "/" + unit.name) +
                    '" target="_blank">' +
                    '<i title="teilen" class="fa fa-xing"></i>' +
                    "</a>" +
                    "</div>"
                  : "") +
                (!!unit.navDelay
                  ? "<div>" +
                    '<a class="ie-nav-delay" href="#">' +
                    'Mehr <i title="navigieren" class="fa fa-ellipsis-h"></i>' +
                    "</a>" +
                    "</div>"
                  : "") +
                (!!unit.linkedin
                  ? "<div>" +
                    '<a href="https://www.linkedin.com/shareArticle?mini=true&url=' +
                    encodeURI(Global.baseUrl + NavigationStack.currentURL + "/" + unit.name) +
                    '" target="_blank">' +
                    '<i title="teilen" class="fa fa-linkedin"></i>' +
                    "</a>" +
                    "</div>"
                  : "") +
                (!!unit.email
                  ? "<div>" +
                    '<a href="mailto:?subject=Geteilter%20Inhalt%20von%20' +
                    encodeURI(Global.baseUrl + NavigationStack.currentURL + "/" + unit.name) +
                    "&body=" +
                    encodeURI(Global.baseUrl + NavigationStack.currentURL + "/" + unit.name) +
                    '">' +
                    '<i title="weiterempfehlen" class="fa fa-envelope"></i>' +
                    "</a>" +
                    "</div>"
                  : "") +
                (!!unit.print
                  ? "<div>" +
                    '<a class="layerlink" href="' +
                    encodeURI(Global.baseUrl + NavigationStack.currentURL + "/" + unit.name) +
                    '/print">' +
                    '<i title="drucken" class="fa fa-print"></i>' +
                    "</a>" +
                    "</div>"
                  : "") +
                (!!unit.nextId
                  ? "<div>" +
                    '<a href="#' +
                    unit.nextId +
                    '">' +
                    '<i title="zu ' +
                    unit.nextId +
                    '" class="fa fa-chevron-right"></i>' +
                    "</a>" +
                    "</div>"
                  : "") +
                "</div>"
              : "") +
            "</div>"
        );
        $box.find(".back").show();
        $box
          .find(".ie-tabs:not(.tab-0)")
          .find(".ie-tab")
          .hide();
        $box.find("#tab-0").show();
        $box.find(".ie-tabnav a").removeClass("active");
        $box.find(".ie-tabnav a:first").addClass("active");
        $box.addClass("ie-maximized");
      }
    } else {
      $box.find(".back").hide();
      $box.find(".front").show();
    }

    module.isotopeGrid.isotope();

    $box.find(".ie-close").click(function(e) {
      //console.log('Button close');
      e.preventDefault();
      //window.location.hash = '';
      ie = module.container.find(".ie");
      ie.removeClass("ie-maximized");
      ie.find(".back").hide();
      ie.find(".back").remove();
      ie.find(".front").show();
      module.isotopeGrid.isotope();
      NavigationStack.reduce();
    });

    if (!!unit.navDelay) {
      $box.find(".ie-nav-delay").click(function(e) {
        //console.log('Button close');
        e.preventDefault();
        //window.location.hash = '';
        NavigationStack.push(unit);
        Breadcrumb.render();
        PageHandler.triggerNavigation(infoRoom);
      });
    }
  };
  module.initGridEventListener = function(infoRoom) {
    if (!!module.isotopeGrid) {
      module.isotopeGrid.on("click", ".front", function(e) {
        var $box = $(this).parent();
        var unit = infoRoom.data.infounits[$box.attr("data-id")];
        if (unit.type != "NAV_EXTERNAL_LINK") {
          e.preventDefault();
          if (!!unit.navMetas) {
            NavigationStack.search = null;
          }
          module.triggerUnit(infoRoom, unit);
          NavigationStack.push(unit);
        }
      });
      module.isotopeGrid.on("click", ".fa-print", function(e) {
        e.preventDefault();
        Router.navigate(NavigationStack.updateTargetURL() + "/print", false);
      });
      module.isotopeGrid.on("click", ".filter", function() {
        //console.log('Filter click');
        $box = $(this);
        unitId = $box.attr("data-id");
        unit = infoRoom.data.infounits[unitId];
        if (!!unit && unit.type != "NAV_WITH_CONTENT") {
          module.isotopeGrid.find(".ie-close").trigger("click");
        }
        /*metas = null;
        if (!!unit) {
          metas = unit.navMetas;
          module.isotopeGrid.isotope({
            filter: function () {
              return infoRoom.metaFilter(metas, $(this).attr('data-id'));
            }
          });
        }*/
        /*if (!!infounit && infounit.type != "NAV_WITH_CONTENT") {
          module.isotopeGrid.find('.ie-close').trigger('click');
        }
        module.isotopeGrid.isotope({
          filter: function () {
            if (!!infounit) {
              return infoRoom.metaFilter(infounit.navMetas, $(this).attr('data-id'));
            }
            return true;
          }
        });*/
        /*if (!!infoRoom.data.infounits[unitId]) {
          NavigationStack.push(infoRoom.data.infounits[unitId]);
        }*/
        /*if (!!infoRoom.data.infounits[unitId] && !module.breadcrumb.html().endsWith('<a href="#" class="filter" data-id="' + unitId + '"><i class="fa fa-caret-right"></i> ' + infoRoom.data.infounits[unitId].displayName + "</a>")) {
          module.breadcrumb.append('<a href="#" class="filter" data-id="' + unitId + '"><i class="fa fa-caret-right"></i> ' + infoRoom.data.infounits[unitId].displayName + "</a>");
        }*/
      });

      module.isotopeGrid.on("click", ".ie-tabnav a", function(e) {
        e.preventDefault();
        if (!$(this).hasClass("active")) {
          module.container
            .find(".ie-tabnav")
            .find("a")
            .removeClass("active");
          $(this).addClass("active");
          $(this)
            .parent()
            .next(".ie-tabs")
            .find(".ie-tab")
            .hide();
          $(this)
            .parent()
            .next(".ie-tabs")
            .find("#tab-" + $(this).attr("tab-id"))
            .show();
        }
      });

      module.isotopeGrid.on("arrangeComplete", function(e) {
        if (!!module.triggeredUnit && !!module.triggeredUnit.offset) {
          $("html, body")
            .stop()
            .stop()
            .animate({ scrollTop: module.triggeredUnit.offset().top }, "slow");
        }
        $.force_appear();
      });

      module.isotopeGrid.on("appear", ".ie:not(.appeared)", function(e, $affected) {
        $(this).addClass("appeared");
        var infounit = infoRoom.data.infounits[$(this).attr("data-id")];
        var front = $(this).find(".front");
        if (!!infounit) {
          if (!!infounit.teaserBackgroundImage) {
            front.css(
              "background",
              "URL(" +
                (infounit.teaserBackgroundImage.indexOf("base64") === -1
                  ? Global.unitUrl + infounit.id + "/file/" + infounit.teaserBackgroundImage
                  : infounit.teaserBackgroundImage) +
                ") no-repeat"
            );
            front.css("background-size", "cover");
            front.css("background-position", "center center");
          }
          if (!!infounit.teaserBackgroundColor) {
            front.css("background-color", infounit.teaserBackgroundColor);
          } else {
            var color = front.css("background-color");
            front.css(
              "background-color",
              !!color && (color != "rgba(0, 0, 0, 0)" || color != "transparent") ? color : "transparent"
            );
          }
          front.css("transition", "opacity .3s ease-in-out");
          front.css("opacity", "1");
        }
        //console.log('appear');
      });
    }
  };
  module.initPageEventListener = function(infoRoom) {
    if (!module.googleAnalytics) {
      module.googleAnalytics = true;
      /*if (!!Global.mainTrackingId) {
        ga('create', Global.mainTrackingId, 'auto'); //Main Tracking id
      }*/

      if (!!infoRoom && !!infoRoom.data && !!infoRoom.data.trackingId) {
        //console.log('trigger init');
        ga("create", infoRoom.data.trackingId, "auto", "clientTracker");
        ga("clientTracker.set", "anonymizeIp", true);
        //ga('create', infoRoom.trackingId, 'auto');
        //ga('set', 'anonymizeIp', true);
      }
      ga("set", "anonymizeIp", true);
    }

    if (!module.quicksearch) {
      module.quicksearch = module.container.find("#search").keyup(
        Global.helperFunctions.debounce(function() {
          //console.log('quicksearch');
          if (module.quicksearch.val() != "") {
            NavigationStack.activateSearch(encodeURI(module.quicksearch.val()));
            /*$qsRegex = new RegExp((module.quicksearch.val()), 'gi');
          module.isotopeGrid.isotope({
            filter: function () {
              var infounit = infoRoom.data.infounits[$(this).attr('data-id')];
              var tmp = ((!!infounit.teaserTitle ? infounit.teaserTitle + ' ' : '') + (!!infounit.teaserSubtitle ? infounit.teaserSubtitle + ' ' : ''));
              tmp += ((!!infounit.title ? infounit.title + ' ' : '') + (!!infounit.subTitle ? infounit.subTitle + ' ' : ''));
              if (!!infounit.tabs) {
                for (var i = 0; i < infounit.tabs.length; ++i) {
                  tmp += (!!infounit.tabs[i].title ? infounit.tabs[i].title : '') + (!!infounit.tabs[i].subTitle ? infounit.tabs[i].subTitle : '') + (!!infounit.tabs[i].text ? infounit.tabs[i].text + ' ' : '');
                }
              }
              return $qsRegex ? + !!tmp.match($qsRegex) : true;
            }
          });*/
          } else {
            NavigationStack.activateSearch(null);
          }
          //$('#breadcrumb .home').nextAll('a').remove();
        }, 500)
      );
    }

    if (!Breadcrumb.container) {
      Breadcrumb.container = module.container.find("#breadcrumb");
      Breadcrumb.container.on("click", ".filter", function(e) {
        e.preventDefault();
        //console.log('breadcrumb click');
        //close all Tiles
        //module.isotopeGrid.find('.ie-close').trigger('click');
        /*metas = null;
        if ($(this).hasClass('home')) {
          metas = infoRoom.data.startMetas; //Set null to disable Startfilter
        }
        else {
          unitId = $(this).attr('data-id');
          infounit = infoRoom.data.infounits[unitId];
          if (!!infounit) {
            metas = infounit.navMetas;
          }
        }
        module.isotopeGrid.isotope({
          filter: function () {
            return infoRoom.metaFilter(metas, $(this).attr('data-id'));
          }
        });*/
        //$(this).nextAll('a').remove();
        module.quicksearch.val("");
        NavigationStack.activateSearch(null, $(this).index());
      });
    }
  };
  return module;
})(Global, Router, NavigationStack, Breadcrumb, window, document);
