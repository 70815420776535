console.log("|-----LOAD: router.js -----");
var Router = (function(Global) {
  var module;
  var nav = $("meta[name=navigationStrategy]");
  if (!!nav) {
    module = new Navigo(Global.baseUrl, !(nav.attr("value") === "false"));
  } else {
    module = new Navigo(Global.baseUrl, false);
  }
  //if (Global.DEBUG) console.log(module);
  module.getUnitsByString = function(unitString) {
    if (!!unitString) {
      return unitString.split("/");
    }
    return null;
  };
  module.routeFunctions = {
    //Prototype Functions for routes.
    printUnit: function(roomName, units) {
      console.log("printUnits " + roomName + " " + units);
    },
    printRoom: function(roomName) {
      console.log("printRoom " + roomName);
    },
    search: function(roomName, search) {
      console.log("search " + roomName + " " + search);
    },
    unit: function(roomName, units) {
      console.log("unit " + roomName + " " + units);
    },
    room: function(roomName) {
      console.log("room " + roomName);
    },
    home: function() {
      console.log("home");
    }
  };
  module.routes = function(routes) {
    module.routeFunctions = routes;
    return module;
  };
  module.start = function() {
    //if (Global.DEBUG) console.log('Create Route Hooks');
    if (
      Global.host === "ares4.info" ||
      Global.host === "ares4dev.de" ||
      Global.host.includes("ares4frontend.localhost") ||
      Global.host === "ares4.space"
    ) {
      module.internalDomain = true;
      //INTERNAL DOMAIN!
      //if (Global.DEBUG) console.log('Internal Domain');
      //room.units.print
      module.on(/\/([^\/]+)\/([a-z0-9\.\/_-]+)\/print/, function(roomName, unitString) {
        module.routeFunctions.printUnit(roomName, module.getUnitsByString(unitString));
      });
      //room.print
      module.on(/\/([^\/]+)\/print/, function(roomName) {
        module.routeFunctions.printRoom(roomName);
      });
      //room.search
      module.on(/\/([^\/]+)\/search\/([^\/]+)(?:\/)?([a-z0-9\.\/_-]*)/, function(roomName, search, unitString) {
        module.routeFunctions.search(roomName, decodeURI(search), module.getUnitsByString(unitString));
      });
      //room.units
      module.on(/\/([^\/]+)\/([a-z0-9\.\/_-]+)/, function(roomName, unitString) {
        module.routeFunctions.unit(roomName, module.getUnitsByString(unitString));
      });
      //room
      module.on(/\/([^\/]+)/, function(roomName) {
        module.routeFunctions.room(roomName);
      });
      //home
      module.on(function() {
        module.routeFunctions.home();
      });
    } else {
      module.internalDomain = false;
      //EXTERNAL DOMAIN!
      //if (Global.DEBUG) console.log('External Domain');
      //room.units.print
      module.on(/\/([a-z0-9\.\/_-]+)\/print/, function(unitString) {
        module.routeFunctions.printUnit(Global.host, module.getUnitsByString(unitString));
      });
      //room.print
      module.on(/\/print/, function() {
        module.routeFunctions.printRoom(Global.host);
      });
      //room.search
      module.on(/\/search\/([^\/]+)(?:\/)?([a-z0-9\.\/_-]*)/, function(search, unitString) {
        module.routeFunctions.search(Global.host, decodeURI(search), module.getUnitsByString(unitString));
      });
      //room.units
      module.on(/\/([a-z0-9\.\/_-]+)/, function(unitString) {
        module.routeFunctions.unit(Global.host, module.getUnitsByString(unitString));
      });
      //room
      module.on(function() {
        module.routeFunctions.room(Global.host);
      });
    }
    module.resolve();
    return module;
  };
  return module;
})(Global);
