/**!
 * @license
 * ares4 v1.0 Bilbao
 *
 * http://rrooaarr.com
 * Copyright 2015 rrooaarr interactive solutions GmbH
 **/
console.log("|-----LOAD: NavigationStack.js -----");
var NavigationStack = (function (Router) {
    var module = {};
    //URL String
    module.base = null;
    //Search String
    module.search = '';
    //ObjectArray
    module.stack = [];
    //TargetObject
    module.target = null;

    module.currentTargetURL = null;
    module.currentURL = null;

    module.init = function (base, search, elements, target) {
        module.stack = [];
        module.base = base;
        module.target = null;
        module.search = search;
        if (!!elements) {
            for (var i = 0; i < elements.length; ++i) {
                if (!!elements[i] && !!elements[i].navMetas && !(i === elements.length - 1 && !!elements[i].navDelay)) {
                    if (!module.getLast() || elements[i].id !== module.getLast().id) {
                        module.stack.push(elements[i]);
                    }
                }
                else if (!!elements[i]) {
                    module.target = elements[i];
                }
            }
            /*elements.forEach(function (element) {
                if (!!element && !!element.navMetas) {
                    if (!module.getLast() || element.id !== module.getLast().id) {
                        module.stack.push(element);
                    }
                }
                else if (!!element) {
                    module.target = element;
                }
            });*/
        }
        if (!!target) {
            module.target = target;
        }
        module.updateTargetURL();
        return module;
    };
    module.activateSearch = function (search, index) {
        module.search = search;
        if (!search) {
            if (!!index || index === 0) {
                module.reduce(index);
            }
            else {
                module.reduce(0);
            }
        }
        else {
            if (Global.DEBUG) console.log('trigger Navigation Search');
            Router.navigate(module.updateTargetURL(), false);
        }
    }
    module.updateTargetURL = function () {
        if (!!module.target) {
            module.currentTargetURL = module.updateURL() + '/' + module.target.name
        }
        else {
            module.currentTargetURL = module.updateURL();
        }
        return module.currentTargetURL;
    }
    module.updateURL = function () {
        var tmpRoute = module.base;
        if (!!module.search) {
            tmpRoute += '/search/' + module.search
        }
        if (!!module.stack) {
            module.stack.forEach(function (item) {
                tmpRoute += '/' + item.name;
            });
        }
        module.currentURL = tmpRoute;
        return module.currentURL;
    };
    module.getLast = function () {
        if (!!module.stack) {
            return module.stack[module.stack.length - 1];
        }
        return null;
    };
    module.getTarget = function () {
        return module.target ? module.target : module.getLast();
    };
    module.push = function (element) {
        if (!!element && !!element.navMetas) {
            module.target = null;
            if (!module.getLast() || element.id !== module.getLast().id) {
                module.stack.push(element);
            }
            if (Global.DEBUG) console.log('trigger Navigation Push 1');
            Router.navigate(module.updateTargetURL(), false);
        }
        else if (!!element) {
            if (!module.target || element.id !== module.target.id) {
                module.target = element;
                if (Global.DEBUG) console.log('trigger Navigation Push 2');
                Router.navigate(module.updateTargetURL(), false);
            }
        }
        return module;
    };
    module.reduce = function (index) {
        if (!!module.stack && (!!index || index === 0)) {
            module.stack = module.stack.splice(0, index);
            //if (Global.DEBUG) console.log("NavigationStack after reduce");
            //if (Global.DEBUG) console.log(module.stack);
        }
        module.target = null;
        if (Global.DEBUG) console.log('trigger Navigation Reduce');
        Router.navigate(module.updateTargetURL(), false);
        return module;
    };
    return module;
})(Router);
