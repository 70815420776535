console.log("|-----LOAD: inforoom.js -----");
var InfoRoom = (function(Global, Router, PageHandler) {
  var module = {};
  module.metaFilter = function(filterMetas, unitId) {
    if (!!filterMetas) {
      unitId = +unitId;
      for (var i = 0; i < filterMetas.length; ++i) {
        var infoUnit = module.data.infounits[unitId];
        if (!!infoUnit && !!infoUnit.metas) {
          for (var j = 0; j < infoUnit.metas.length; ++j) {
            if (infoUnit.metas[j].id === filterMetas[i].id) {
              return true;
            }
          }
        }
      }
      return false;
    }
    return true;
  };
  module.loadCss = function(urlArray, callback) {
    /*$.getManyCss([Global.roomFileUrl + 'css/' + module.data.css], function () {
            //if (Global.DEBUG) console.log("CSS Loaded");
            setTimeout(function () {
                callback.call();
            }, 1000);
        });*/
    callback.call();
  };
  module.loadRoom = function(roomUrl, force, callback) {
    // Evt 2. Callback für CssLaden
    var subCallback = function() {
      callback.call();
    };
    if (force || !module.data) {
      console.log(
        "F:inforoom|M:loadroom|V:roomUrl - fetch data from roomUrl: ",
        roomUrl
      );
      fetch(roomUrl)
        .then(function(result) {
          return result.json();
        })
        .then(function(roomDataResult) {
          console.log("F:inforoom.js|M:loadroom|V:result: ", roomDataResult);
          module.data = roomDataResult;
          subCallback.call();
        });
    } else {
      subCallback.call();
    }
    return null;
  };
  module.initEventListener = function() {};
  module.getUnitIndexByNameOrId = function(search) {
    if (Global.DEBUG) console.log('getUnitIndexByNameOrId ' + search);
    if (!!module.data && !!module.data.infounits) {
      if (typeof search == "number") {
        return search;
      } else {
        for (unit in module.data.infounits) {
          if (Global.DEBUG) console.log('for unit ' + module.data.infounits[unit].searchTerms);
          if (module.data.infounits[unit].name == search) {
            return module.data.infounits[unit].id;
          }
        }
      }
    }
    return null;
  };
  module.getUnitByName = function(name) {
    if (Global.DEBUG) console.log('getUnitByName ' + name);
    var tmp = module.getUnitIndexByNameOrId(name);
    if (!!tmp) {
      return module.data.infounits[tmp];
    }
    return null;
  };
  module.getIdByName = function(name) {
    var tmp = module.getUnitIndexByNameOrId(name);
    if (!!tmp) {
      return module.data.infounits[tmp].id;
    }
    return null;
  };
  return module;
})(Global, Router, PageHandler);
