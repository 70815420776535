console.log("|-----LOAD: global.js -----");
var Global = (function(window) {
  var module = {};

  module.protocol = window.location.protocol;
  module.host = window.location.hostname;
  module.baseUrl = module.protocol + "//" + module.host;

  module.apiProtocol = module.host.includes(".localhost") || module.host === "ares4.space" ? "http:" : "https:";
  module.apiHost = null;
  if (module.host.includes(".localhost")) {
    module.apiHost = "ares4api.localhost";
  } else if (module.host === "ares4.space") {
    module.apiHost = "api.ares4.space";
  } else if (module.host === "ares4dev.de") {
    module.apiHost = "api.ares4dev.de";
  } else {
    module.apiHost = "api.ares4.info";
  }
  module.apiBaseUrl = module.apiProtocol + "//" + module.apiHost;
  module.apiVersion = "v0.1";
  module.apiVersionUrl = module.apiBaseUrl + "/" + module.apiVersion;

  module.room =
    module.host === "ares4.info" ||
    module.host === "ares4frontend.localhost" ||
    module.host === "ares4.space" ||
    module.host === "ares4dev.de"
      ? window.location.pathname.split("/")[1].replace("/", "")
      : module.host;
  module.roomUrl = module.apiVersionUrl + "/inforoom/" + module.room;
  module.roomFileUrl = module.roomUrl + "/file/";
  module.unitUrl = module.roomUrl + "/infounit/";
  module.DEBUG = module.host.includes(".localhost") || module.host === "ares4.space";

  module.mainTrackingId = module.DEBUG ? "UA-83610339-1" : "UA-100410248-1"; // 'UA-100410248-1' Main System

  //Helper Functions
  module.helperFunctions = {
    debounce: function(fn, threshold) {
      var timeout;
      return function debounced() {
        if (timeout) {
          clearTimeout(timeout);
        }

        function delayed() {
          fn();
          timeout = null;
        }
        timeout = setTimeout(delayed, threshold || 100);
      };
    }
  };
  return module;
})(window);
